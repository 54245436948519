import React, { FC, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { HORIZONTAL_PADDING } from "../../styles/global";
import Close from "../icons/Close";
import MenuIcon from "../icons/MenuIcon";

type Props = {};

const Nav: FC<Props> = ({}) => {
  const [isExpanded, toggleExpansion] = useState(false);

  const handleMenuToggle = () => {
    toggleExpansion(false);
  };

  return (
    <nav
      id="nav"
      className={`fixed top-0 w-screen flex z-50 justify-between py-3 md:py-4 bg-black flex-wrap ${HORIZONTAL_PADDING}`}
    >
      <div className="flex items-center relative ">
        <Link to="/">
          <StaticImage
            src="../../assets/logo/logo.svg"
            alt="TeqDen logo"
            className="w-28 "
          />
        </Link>
      </div>
      <div className="block lg:hidden px-4">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="flex px-2 py-2 border rounded"
        >
          {isExpanded ? <Close fill="#FFFFFF" /> : <MenuIcon fill="#FFFFFF" />}
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow lg:flex lg:w-auto `}
      >
        <div
          className={`${
            isExpanded ? `flex-col space-y-5 py-10` : `flex-row`
          } text-sm lg:flex-grow flex items-center justify-end uppercase font-medium bg-black  text-white`}
        >
          <Link to="/events" className="pr-4" onClick={handleMenuToggle}>
            <h5>🗓️ EVENTS</h5>
          </Link>
          <Link to="/podcast" className="pr-4" onClick={handleMenuToggle}>
            <h5>🎙️ PODCAST</h5>
          </Link>
          <Link to="/about" className="pr-4" onClick={handleMenuToggle}>
            <h5>🚀 ABOUT</h5>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
