import React, { type FC } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { HORIZONTAL_PADDING } from "../../styles/global";
import Youtube from "../icons/Youtube";
import Linkedin from "../icons/Linkedin";
import Tiktok from "../icons/Tiktok";
import Twitter from "../icons/Twitter";
import Instagram from "../icons/Instagram";

const CONTAINER_CLASSES = "flex justify-center items-center w-full";

const Footer: FC = () => {
  return (
    <footer className={`relative w-full bg-black text-white overflow-hidden`}>
      <div className="absolute w-full object-cover h-full">
        <StaticImage
          src="../../assets/images/microphone-footer.png"
          alt="Microphone"
          className="absolute w-full object-cover h-full"
        />
      </div>
      <div
        className={`relative ${HORIZONTAL_PADDING} flex flex-col items-center justify-center gap-8 p-16`}
      >
        <div>
          <h3 className="flex justify-center text-center md:text-left text-[32px] md:text-5xl">
            a space for innovators to share their ideas
          </h3>
          <h5 className="flex justify-center pb-10 text-center md:text-left text-white/60">
            a stage for others to help support young and exciting companies
          </h5>
        </div>

        <div className="flex md:flex-row flex-col text-center gap-5 justify-center">
          <a
            href="https://www.linkedin.com/company/teqden/"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin className="w-10 md:w-full" />
          </a>
          <a href="https://twitter.com/TeqDen" target="_blank" rel="noreferrer">
            <Twitter className="w-10 md:w-full" />
          </a>
        </div>
        <div className="flex md:flex-row flex-col justify-center gap-3">
          <div className={`${CONTAINER_CLASSES}`}>
            <Link to="/about" className="pr-4">
              <h5>About</h5>
            </Link>
            <Link to="/podcast" className="pr-4">
              <h5>Podcasts</h5>
            </Link>
          </div>
          <div className={`${CONTAINER_CLASSES}`}>
            <Link to="/events" className="pr-4">
              <h5>Events</h5>
            </Link>
            <Link to="/contact" className="pr-4">
              <h5>Contact</h5>
            </Link>
          </div>
          <div className={`${CONTAINER_CLASSES}`}>
            <a
              href="https://www.scalarnorthcapital.co.uk/"
              target="_blank"
              rel="noreferrer"
              className="pr-4"
            >
              <h5>ScalarNorthCapital</h5>
            </a>
          </div>
        </div>
      </div>
      <div
        className={`relative z-10 flex w-full bg-black py-4 justify-center ${HORIZONTAL_PADDING}`}
      >
        <span className="text-xs">
          © {new Date().getFullYear()} Copyright Scalar North Capital Limited.{" "}
          <span className="text-white/60">
            Company registered in England and Wales: 12507895. VAT registration
            number: 421664315
          </span>{" "}
          <span className="pl-2">
            {" "}
            Website by{" "}
            <span className="italic font-extralight">
              <a
                href="https://loopspeed.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Loopspeed
              </a>
            </span>
          </span>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
