import React, { FC } from "react";

type Props = {
  className?: string;
  fill?: string;
};

const Close: FC<Props> = ({ className, fill = "white" }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.48384 13.65L0.433838 12.6L6.03384 6.99998L0.433838 1.39998L1.48384 0.349976L7.08384 5.94998L12.6838 0.349976L13.7338 1.39998L8.13384 6.99998L13.7338 12.6L12.6838 13.65L7.08384 8.04998L1.48384 13.65Z"
        fill={fill}
      />
    </svg>
  );
};

export default Close;
