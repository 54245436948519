export const HORIZONTAL_PADDING =
  "w-full px-8 md:px-16 lg:px-24 xl:px-[8vw] 2xl:px-[16vw]";

export const P_CLASSES = "text-md lg:text-lg leading-loose font-light";

export const SECTION_CLASSES =
  "relative w-full md:h-[75vh] h-[100vh] md:max-[720px] lg:max-h-[800px]" +
  HORIZONTAL_PADDING;

// TODO: Add letter spacing and line heights here
export const H1_ClASSES = "text-4xl md:text-6xl lg:text-7xl font-bold";

export const H2_CLASSES = "text-3xl md:text-4xl lg:text-5xl font-bold";

export const H3_CLASSES = "text-2xl md:text-3xl lg:text-4xl font-bold";

export const GREEN_LINEAR =
  "text-transparent bg-clip-text bg-gradient-to-r from-green-linear to-light-green-linear";

export const BLUE_LINEAR =
  "text-transparent bg-clip-text bg-gradient-to-r from-blue-linear to-light-blue-linear";
