import React, { FC } from "react";

type Props = {
  className?: string;
};

const Twitter: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56 13.8741C54.086 14.7241 52.03 15.2961 49.872 15.5541C52.076 14.2341 53.766 12.1441 54.562 9.65207C52.502 10.8741 50.218 11.7621 47.786 12.2421C45.84 10.1681 43.068 8.87207 40 8.87207C34.108 8.87207 29.332 13.6501 29.332 19.5401C29.332 20.3761 29.428 21.1921 29.608 21.9701C20.742 21.5261 12.882 17.2781 7.61805 10.8221C6.70205 12.3981 6.17605 14.2301 6.17605 16.1881C6.17605 19.8881 8.05805 23.1541 10.92 25.0661C9.17205 25.0101 7.52605 24.5301 6.08805 23.7321C6.08805 23.7781 6.08805 23.8201 6.08805 23.8661C6.08805 29.0361 9.76405 33.3481 14.646 34.3261C13.752 34.5701 12.808 34.7001 11.834 34.7001C11.148 34.7001 10.478 34.6321 9.82805 34.5101C11.186 38.7481 15.126 41.8341 19.794 41.9201C16.144 44.7821 11.544 46.4881 6.54405 46.4881C5.68405 46.4881 4.83405 46.4381 3.99805 46.3381C8.72005 49.3641 14.326 51.1301 20.352 51.1301C39.976 51.1301 50.704 34.8741 50.704 20.7761C50.704 20.3141 50.694 19.8541 50.674 19.3961C52.76 17.8901 54.57 16.0121 56 13.8741Z"
        fill="white"
      />
    </svg>
  );
};

export default Twitter;
