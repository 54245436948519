import React from "react";
import { FC, PropsWithChildren } from "react";
import Footer from "../footer/Footer";
import Nav from "../nav/Nav";

type Props = PropsWithChildren<{}>;

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
