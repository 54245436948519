import React, { FC } from "react";

type Props = {
  className?: string;
  fill?: string;
};

const MenuIcon: FC<Props> = ({ className, fill = "white" }) => {
  return (
    <svg
      className={className}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 11.625V10.125H17.75V11.625H0.25ZM0.25 6.75V5.25H17.75V6.75H0.25ZM0.25 1.875V0.375H17.75V1.875H0.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default MenuIcon;
